import { NextSeo } from 'next-seo'
import { InferGetStaticPropsType } from 'next'
import { gql } from '@apollo/client'
import { extractImgSrc } from '@plaiceholder/tailwindcss/utils'
import { getPlaiceholder } from 'plaiceholder'
import { client } from '../services/apollo-client'
import trustPilotData from '../data/trustpilot.json'
import { ImageProps } from '../components/ui'
import TrustpilotBox from '../components/Trustpilot/trustpilot-box'
import ReviewFeed from '../components/review/review-feed'
import ProductRelatedArticles from '../components/product/product-related-articles'
import StatsBlock from '../components/ui/stats-block'
import PrivacyPromise from '../components/ui/privacy-promise'
import { resolveArticlesBySlugs } from '../helpers/blogs'
import RelatedProductsBlock from '../components/product/related-products-block'
import { RelatedProductProps } from '../components/product/related-product-card'
import AsFeaturedIn from '../components/ui/as-featured-in'
import OpenQuoIcon from '@/images/icons/openQuo_icon.svg?svgr'
import ManHeadAnime from '@/images/illustrations/smallManHead.png'
import ManWithSurfboard from '@/images/illustrations/manWithSurfboard.png'
import ElderlyCouple from '@/images/illustrations/elderlyCouple.png'
import WomanWalkingDog from '@/images/illustrations/womanWalkingDog.png'
import WomanInWheelchair from '@/images/illustrations/womanInWheelchair.png'
import ManHoldingPlants from '@/images/illustrations/manHoldingPlants.png'
import PairOfRunners from '@/images/illustrations/pairOfRunners.png'
import PregnantWomanInHeadscarf from '@/images/illustrations/pregnantWomanInHeadscarf.png'
import Family1 from '@/images/illustrations/family1.png'
import WomanOnPhoneInBed from '@/images/illustrations/womanOnPhoneInBed.png'
import OlderManWorkingOut from '@/images/illustrations/olderManWorkingOut.png'
import TeenagerOnPhone from '@/images/illustrations/teenagerOnPhone.png'
import WomanRunning from '@/images/illustrations/womanRunning.png'
import Cyclist from '@/images/illustrations/cyclist.png'
import LandingCombo1 from '@/images/illustrations/landingCombo1.png'
import LandingCombo2 from '@/images/illustrations/landingCombo2.png'
import ChildrenPlaying_2x from '@/images/illustrations/childrenPlaying_2x.png'
import Cyclist_2x from '@/images/illustrations/cyclist_2x.png'
import ChildrenAndCyclist from '@/images/illustrations/childrenAndCyclist.png'
import AlasdairBodyImage from '@/images/team/alasdair_teal_bg.jpg'
import WomanHeadAnime from '@/images/illustrations/smallWomanHead.png'
import ClaireBodyImage from '@/images/team/claire_peach_bg.jpg'
import {
  BlogCardFragmentDoc,
  MainPageArticlesQuery,
  MainPageArticlesQueryVariables,
  TestProductSummaryBySlugQuery,
  TestProductSummaryBySlugQueryVariables,
} from '@/gql'
import * as UI from '@/ui'

export type HomePageTestProductProps = {
  highlightProducts: RelatedProductProps[]
  trendingArticles: InferGetStaticPropsType<typeof getStaticProps>['trendingArticles']
}

const HomePage = ({ highlightProducts, trendingArticles }: HomePageTestProductProps) => {
  if (highlightProducts.length === 0) throw new Error('No product data')

  return (
    <UI.Block gap="3xl">
      <script
        id="homepageJsonLd"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
      />

      <NextSeo
        title="Become the expert in you with our at home health tests"
        description="Learn about your health with our at home blood & stool tests. We have tests to check for IBS, coeliac disease, diabetes, bowel cancer & more, all reviewed by doctors."
      />

      {/* "Hero" section */}
      <UI.Block
        className="mx-auto max-w-7xl items-center justify-between gap-y-6 px-4 sm:pt-10 md:px-6 lg:gap-y-12"
        display={'flexCol'}
      >
        <UI.Heading size={{ default: 'large', lg: 'xl' }} className="text-center">
          The better way to better health.
        </UI.Heading>
        <UI.Block gap="large" className="mx-auto text-center md:max-w-4xl">
          <UI.Block gap="small">
            <UI.Heading weight="thin" size={{ default: 'xs', lg: 'small' }} as="h2">
              Thanks to Selph, improving your health has never been easier.
            </UI.Heading>

            <UI.Heading weight="thin" size={{ default: 'xs', lg: 'small' }} as="h2">
              Thousands of people trust Selph for their health tests.
            </UI.Heading>
          </UI.Block>

          <UI.Link type="button" to="tests" size="xl">
            Browse our tests
          </UI.Link>
        </UI.Block>
        <div className="mx-auto flex max-w-5xl items-baseline justify-center md:-mt-4 lg:-mt-8">
          <UI.Image
            width={200}
            priority
            src={ManWithSurfboard}
            alt="Man with surfboard"
            className="-mx-8 hidden h-auto shrink-0 md:block"
          />

          <UI.Image
            width={187}
            priority
            src={ElderlyCouple}
            alt="Elderly couple"
            className="-mx-10 h-auto shrink-0 sm:-mx-8"
          />
          <UI.Image
            width={200}
            priority
            src={WomanWalkingDog}
            alt="Woman walking dog"
            className="-mx-8 h-auto shrink-0"
          />
          <UI.Image
            width={200}
            priority
            src={WomanInWheelchair}
            alt="Woman in wheelchair"
            className="-mx-14 h-auto shrink-0 sm:-mx-8"
          />
          <UI.Image
            width={200}
            priority
            src={ManHoldingPlants}
            alt="Man holding plants"
            className="-mx-12 h-auto shrink-0 sm:-mx-8"
          />
          <UI.Image
            width={200}
            priority
            src={PairOfRunners}
            alt="Pair of runners"
            className="-mx-10 h-auto shrink-0 sm:-mx-8"
          />
          <UI.Image
            width={200}
            priority
            src={PregnantWomanInHeadscarf}
            alt="Pregnant woman in headscarf"
            className="-mx-10 h-auto shrink-0 sm:-mx-8"
          />
          <UI.Image width={200} priority src={Family1} alt="Family" className="-mx-8 hidden h-auto shrink-0 md:block" />
        </div>
      </UI.Block>

      {/* "As featured in" section */}
      <div className="mx-auto my-20 max-w-7xl md:my-40">
        <AsFeaturedIn />
      </div>

      {/* "Our Mission" section */}
      <div className="mx-auto max-w-7xl px-4 md:px-6">
        <UI.Grid size={{ default: 1, md: 5 }} align="center" gap="xxl" colVerticalAlign="middle">
          {/* Row-1 */}
          <>
            <UI.GridSpan size={1} className="w-40 max-md:hidden lg:w-50">
              <UI.Image src={ManHoldingPlants} alt="Man holding plants" />
            </UI.GridSpan>
            <UI.GridSpan size={{ md: 3, lg: 2 }} className="w-40 max-md:hidden lg:w-50">
              <UI.Image src={WomanOnPhoneInBed} alt="Woman on phone in bed" className="translate-y-[25px]" />
            </UI.GridSpan>
            <UI.GridSpan size={1} className="w-40 max-lg:hidden lg:w-50">
              <UI.Image src={WomanInWheelchair} alt="Woman in wheelchair" className="scale-x-[-1]" />
            </UI.GridSpan>
            <UI.GridSpan size={1} className="w-40 max-md:hidden lg:w-50">
              <UI.Image src={Family1} alt="Family" className="translate-y-[30px]" />
            </UI.GridSpan>
          </>

          {/* Row-2 */}
          <>
            <UI.GridSpan size={1} className="w-40 max-md:hidden lg:w-50">
              <UI.Image
                src={OlderManWorkingOut}
                alt="Older man working out"
                className="translate-x-[30px] translate-y-[25px]"
              />
            </UI.GridSpan>
            <UI.GridSpan size={{ default: 1, md: 3 }}>
              <UI.Block gap="large" className="mx-auto text-center">
                <UI.Badge color="outline" type="round">
                  Our Mission
                </UI.Badge>

                <UI.Heading weight="regular" size={{ default: 'medium', lg: 'large' }} as="h2">
                  What&apos;s more important than good health?
                </UI.Heading>

                <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>
                  <strong>Nothing.</strong> But there are too many barriers to achieving it. Getting tests, expert
                  medical advice and a tailor-made health plan should be quick and easy but it isn&apos;t.
                  <br />
                  Now, with Selph, it is.
                </UI.Paragraph>
              </UI.Block>
            </UI.GridSpan>
            <UI.GridSpan size={1} className="w-40 max-md:hidden lg:w-50">
              <UI.Image
                src={PregnantWomanInHeadscarf}
                alt="Pregnant woman in headscarf"
                className="translate-x-[-50px] translate-y-[25px] scale-x-[-1]"
              />
            </UI.GridSpan>
          </>

          {/*  Row-3 */}
          <>
            <UI.GridSpan size={1} className="w-40 max-md:hidden lg:w-50">
              <UI.Image src={TeenagerOnPhone} alt="Teenager on phone" className="translate-y-[-25px]" />
            </UI.GridSpan>
            <UI.GridSpan size={1} className="w-40 max-lg:hidden lg:w-50">
              <UI.Image src={WomanRunning} alt="Woman running" />
            </UI.GridSpan>
            <UI.GridSpan size={{ md: 3, lg: 2 }} className="w-40 max-md:hidden lg:w-50">
              <UI.Image src={Cyclist} alt="Cyclist" className="translate-y-[25px]" />
            </UI.GridSpan>
            <UI.GridSpan size={1} className="w-40 max-md:hidden lg:w-50">
              <UI.Image src={WomanWalkingDog} alt="Woman walking dog" className="translate-y-[-10px] scale-x-[-1]" />
            </UI.GridSpan>
          </>
        </UI.Grid>
      </div>

      {/* "Spend less time" section */}
      <div className="mx-auto max-w-7xl px-4 md:px-6">
        <div className="flex gap-10 max-md:flex-col md:gap-14 lg:gap-24">
          <div className="relative w-full max-md:h-80 md:w-1/2">
            <UI.Image
              fill
              src={LandingCombo1}
              sizes="(max-width: 768px) 320px, 50vw"
              alt="Couple and grandchild enjoying life."
              className="aspect-square object-contain"
            />
          </div>

          <UI.Block className="md:w-1/2">
            <UI.Heading weight="regular" size={{ default: 'medium', lg: 'large' }} as="h2">
              Spend less time worrying about your health and more time enjoying it.
            </UI.Heading>
            <UI.Block>
              <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>
                You don&apos;t always know what&apos;s right for your health, but when you do, wouldn&apos;t it be great
                if you could just get it?
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>
                Take testing for example. You shouldn&apos;t have to waste time waiting for a doctor to get a simple
                health test. At Selph, we make testing quick, easy and simple to understand. And you can discuss your
                results at a time that suits you.
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>
                But quick and easy testing is just the beginning. Your health affects everything you do every day of
                your life and we want to make it all easier. So we&apos;re creating a better way to doctors
                appointments, prescriptions, expert advice and living better for longer.
              </UI.Paragraph>
            </UI.Block>
            {/* TOD: @as703 to check/update this link */}
            <UI.Link to="about" type="button">
              Find out more about Selph
            </UI.Link>
          </UI.Block>
        </div>
      </div>

      {/* "Customer Stories" section */}
      <div className="w-full bg-selphGreen-500">
        <div className="mx-auto max-w-7xl md:relative md:flex md:justify-center">
          <UI.Block
            gap="large"
            display="flexCol"
            id="customers-review-block"
            className="px-4 py-16 text-center md:px-0"
          >
            <UI.Badge size="medium" color="outline" className="mx-auto text-center text-selphWhite-500">
              Don&apos;t just take our word for it
            </UI.Badge>

            <UI.Heading
              color="white"
              weight="regular"
              className="mx-auto max-w-xl"
              size={{ default: 'medium', lg: 'large' }}
              as="h2"
            >
              Customer Stories
            </UI.Heading>

            <UI.Paragraph color="white" size={{ default: 'medium', lg: 'large' }}>
              At Selph, we&apos;ve helped thousands of people feel better today and live longer tomorrow.
            </UI.Paragraph>

            <div className="relative mt-10 mb-16 md:left-5">
              <ReviewFeed reviews={reviews} />
            </div>

            <div className="mx-auto text-center">
              <TrustpilotBox boxStyle="microHeader" darkBg />
            </div>
          </UI.Block>
        </div>
      </div>

      {/* "BetterHealth" section */}
      <div className="mx-auto max-w-7xl px-4 md:px-6">
        <div className="flex gap-10 max-md:flex-col md:gap-14 lg:gap-24">
          <UI.Block className="order-1 w-full md:order-2 md:w-1/2">
            <UI.Badge color="outline" type="round" className="w-fit">
              BetterHealth
            </UI.Badge>

            <UI.Heading size={{ default: 'medium', lg: 'large' }} as="h2">
              Enjoy BetterHealth with Selph.
            </UI.Heading>
            <UI.Block>
              <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>
                We all want to enjoy longer, healthier lives, doing more of what we love with the people we love. But
                traditional healthcare is more about treatment than prevention, and settles for results that are ok,
                rather than optimal.
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', lg: 'large' }}>
                So we&apos;ve created BetterHealth - a personalised doctor-led service to help you improve your health
                and enjoy it for longer.
              </UI.Paragraph>
            </UI.Block>

            <UI.Link to="betterhealth" type="button" containerClassName="text-center md:text-left">
              Enjoy BetterHealth
            </UI.Link>
          </UI.Block>

          <div className="relative order-2 w-full max-md:h-80 md:order-1 md:w-1/2">
            <UI.Image
              fill
              src={LandingCombo2}
              sizes="(max-width: 768px) 320px, 50vw"
              alt="Family and person roller skating"
              className="aspect-square object-contain"
            />
          </div>
        </div>
      </div>

      {/* "The benefits of BetterHealth" section */}
      <div className="mx-auto max-w-7xl px-4 md:px-6">
        <div className="flex gap-10 max-md:flex-col md:gap-14 lg:gap-24">
          <UI.Block className="flex w-full justify-between md:w-[45%]" display="flexCol">
            <UI.Block>
              <UI.Heading size={{ default: 'small', lg: 'medium' }} as="p">
                You can have BetterHealth, you just need to start.
              </UI.Heading>

              <UI.Link to="betterhealth" type="button" containerClassName="text-center md:text-left">
                Enjoy BetterHealth
              </UI.Link>
            </UI.Block>

            <div className="relative mx-auto h-full w-full max-w-md max-md:hidden">
              <UI.Image
                fill
                src={ChildrenAndCyclist}
                sizes="(max-width: 768px) 320px, 50vw"
                alt="Family and person roller skating"
                className="aspect-square object-contain"
              />
            </div>
          </UI.Block>

          <UI.Block className="w-full md:w-[55%]">
            <UI.Heading as="h3" size={{ default: 'xs', lg: 'small' }}>
              With BetterHealth you&apos;ll enjoy:
            </UI.Heading>

            {benefits.map((benefit) => (
              <Benefit key={benefit.image} benefit={benefit} />
            ))}
            <div className="relative mx-auto flex h-[150px] w-full max-w-md md:hidden">
              <div className="relative w-1/2">
                <UI.Image
                  fill
                  src={ChildrenPlaying_2x}
                  sizes="225px"
                  alt="Children playing animation image"
                  className="max-w-75 object-scale-down"
                />
              </div>
              <div className="relative w-1/2">
                <UI.Image
                  fill
                  src={Cyclist_2x}
                  sizes="225px"
                  alt="Cyclist animation image"
                  className="max-w-75 object-scale-down"
                />
              </div>
            </div>
          </UI.Block>
        </div>
      </div>

      {/* "Popular Health tests" section */}
      {highlightProducts.length > 0 && (
        <div className="mx-auto max-w-7xl px-4 md:px-6">
          <RelatedProductsBlock relatedProducts={highlightProducts} title="Popular Health Tests" />
        </div>
      )}

      {/* "Doctor's" section */}
      <div className="w-full bg-selphGreen-500">
        <UI.Block className="mx-auto max-w-7xl px-4 py-18 md:px-6">
          <UI.Badge color="outline" type="round" className="text-selphWhite-500">
            Our Experts
          </UI.Badge>

          <UI.Block gap="xl">
            <UI.Heading as="h2" color="white" className="w-full md:w-1/2" size={{ default: 'small', lg: 'medium' }}>
              We prioritise our people so they prioritise you.
            </UI.Heading>

            <UI.Block gap="xl">
              {/* Claire */}
              <div className="flex flex-col overflow-hidden rounded-3xl md:h-[430px] md:flex-row">
                <div className="relative aspect-square w-full max-md:h-[225px] md:w-[45%]">
                  <UI.Image
                    src={ClaireBodyImage}
                    placeholder="blur"
                    fill
                    sizes="(max-width: 768px) 300px, 550px"
                    alt="Dr Claire Merrifield MRCGP PhD"
                    className="bg-selphAmber-200 object-contain object-bottom"
                  />
                  <OpenQuoIcon width={40} className="absolute top-6 left-6 text-selphAmber-500" />
                </div>

                <UI.Block
                  display="flexCol"
                  className="w-full items-start justify-between bg-selphGreen-400 p-3 align-middle sm:p-6 md:w-[55%] md:p-10 lg:p-12"
                >
                  <UI.Heading as="h3" color="white" size={{ default: 'xs', md: 'small', lg: 'medium' }}>
                    &ldquo; Preventative, proactive healthcare
                  </UI.Heading>

                  <UI.Paragraph color="white" size={{ default: 'medium', lg: 'large' }}>
                    I left the NHS to set up a company that&apos;s about preventative, proactive healthcare.{' '}
                    <span className="font-normal">We need to stop waiting for illness to just happen to us.</span> Your
                    health is the most precious gift you&apos;ll ever have. Selph can help you take care of it.&quot;
                  </UI.Paragraph>

                  <div className="flex items-center gap-x-2">
                    <div className="relative aspect-square w-12 sm:w-16">
                      <UI.Image src={WomanHeadAnime} alt="Woman head illustration" fill />
                    </div>
                    <UI.Block gap="xs">
                      <UI.Paragraph color="white" weight="regular" size={{ default: 'medium', lg: 'large' }}>
                        Dr Claire Merrifield MRCGP PhD
                      </UI.Paragraph>
                      <UI.Paragraph color="amber" size={{ default: 'small', lg: 'medium' }}>
                        Our Medical Director
                      </UI.Paragraph>
                    </UI.Block>
                  </div>
                </UI.Block>
              </div>

              {/* Alasdair */}
              <div className="flex flex-col overflow-hidden rounded-3xl md:h-[430px] md:flex-row">
                <div className="relative order-1 aspect-square w-full max-md:h-[225px] md:order-2 md:w-[45%]">
                  <UI.Image
                    src={AlasdairBodyImage}
                    placeholder="blur"
                    fill
                    sizes="(max-width: 768px) 300px, 550px"
                    alt="Dr Alasdair Scott FRCS PhD"
                    className="bg-selphTeal-200 object-contain object-bottom"
                  />
                  <OpenQuoIcon width={40} className="absolute top-6 left-6 text-selphTeal-500" />
                </div>

                <UI.Block
                  display="flexCol"
                  className="order-2 w-full items-start justify-between bg-selphGreen-400 p-3 align-middle sm:p-6 md:order-1 md:w-[55%] md:p-10 lg:p-12"
                >
                  <UI.Heading color="white" size={{ default: 'xs', md: 'small', lg: 'medium' }}>
                    &ldquo; Let&apos;s stop being &quot;normal&quot;
                  </UI.Heading>

                  <UI.Paragraph color="white" size={{ default: 'medium', lg: 'large' }}>
                    Traditional healthcare wants us to be &quot;normal&quot;. But normal people get heart disease,
                    cancer and dementia. <span className="font-normal">So let&apos;s forget about normal.</span> Selph
                    can not only show you what optimal health looks like, we can help you get there.&quot;
                  </UI.Paragraph>

                  <div className="flex items-center gap-x-2">
                    <div className="relative aspect-square w-12 sm:w-16">
                      <UI.Image src={ManHeadAnime} alt="Man head illustration" fill />
                    </div>
                    <UI.Block gap="xs">
                      <UI.Paragraph color="white" weight="regular" size={{ default: 'medium', lg: 'large' }}>
                        Dr Alasdair Scott FRCS PhD
                      </UI.Paragraph>
                      <UI.Paragraph color="amber" size={{ default: 'small', lg: 'medium' }}>
                        Our Science Director
                      </UI.Paragraph>
                    </UI.Block>
                  </div>
                </UI.Block>
              </div>
            </UI.Block>
          </UI.Block>
        </UI.Block>
      </div>
      <UI.Block gap="3xl" className="mx-auto max-w-7xl px-4 md:px-6">
        {/* "Trending Articles" section */}

        <ProductRelatedArticles blogs={trendingArticles} title="Trending Articles" />

        {/* "Statistics Blocks" section */}
        <div className="md:pr-6">
          <StatsBlock />
        </div>

        {/* "Privacy Promise" section */}

        <PrivacyPromise />
      </UI.Block>
    </UI.Block>
  )
}

HomePage.layoutProps = {
  containerStyle: 'verticalContained',
}

export default HomePage

const slugs = [
  'q-fit-bowel-cancer-test',
  'prostate-specific-antigen-psa-blood-test',
  'general-health-blood-test',
  'complete-gut-health-test-symptom-screen',
]

const articleSlugs = [
  '7-evidenced-based-actions-you-can-take-to-prevent-bowel-cancer-or-pick-it-up-earlier',
  'when-should-you-start-screening-for-bowel-cancer',
  'what-is-hba1c-and-whats-the-hba1c-normal-range',
]

export const getStaticProps = async () => {
  const highlightProducts = await Promise.all(slugs.map((slug) => getProductBySlug(slug)))
  const trendingArticles = await getTrendingArticles(articleSlugs)

  return {
    props: {
      highlightProducts,
      trendingArticles,
    },
  }
}

const reviews = [
  {
    link: 'https://uk.trustpilot.com/reviews/66642eafe296f323117f689e',
    author: 'Joanna',
    date: '2024-06-08',
    content:
      'This is the company for our family. It is rare to encounter such a personal touch as a customer in any sphere of business or public service nowadays. I am so impressed and grateful for Selph.',
  },
  {
    link: 'https://uk.trustpilot.com/reviews/6618e607fb54990df59b86d2',
    author: 'Tony Longhurst',
    date: '2024-04-12',
    content:
      'I ordered a PSA kit which was easy to use and by following all instructions i had my results along with peace of mind within 48 hours. With such a short time to wait for results it really does reduce any anxiety.',
  },
  {
    link: 'https://uk.trustpilot.com/reviews/65ce4a605b2c3332ffdfd6e1',
    author: 'Anna',
    date: '2024-02-15',
    content:
      'Wonderful company, clear instructions. I sent my sample off Tuesday afternoon and had my results Wednesday afternoon. The results were very clear and I was able to put my mind at rest very quickly.',
  },
]

const jsonLdData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  '@id': 'https://www.selph.co.uk/',
  name: 'Selph',
  description: 'Become an expert in your health',
  url: 'https://www.selph.co.uk/',
  image: '/images/thumb.png',
  logo: '/images/logo.svg',
  sameAs: [
    'https://www.facebook.com/selphhealthuk',
    'https://twitter.com/selphhealthuk',
    'https://www.instagram.com/selphhealthuk',
    'https://www.linkedin.com/company/selphhealth/',
    'https://www.youtube.com/@SelphHealth',
  ],
  address: {
    '@type': 'PostalAddress',
    streetAddress: '124 City Road',
    addressLocality: 'London',
    postalCode: 'EC1V 2NX',
    addressCountry: 'GB',
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: trustPilotData.rating,
    bestRating: 5,
    ratingCount: trustPilotData.reviews,
  },
}

async function getProductBySlug(slug: string) {
  const response = await client.query<TestProductSummaryBySlugQuery, TestProductSummaryBySlugQueryVariables>({
    query: testProductSummaryBySlug,
    variables: { slug },
  })

  const product = response.data?.productBySlug?.__typename === 'LabTestProduct' ? response.data.productBySlug : null

  if (!product) return null

  const placeholder = await getPlaiceholder(extractImgSrc(`/images/product/${product.featuredImage.src}`))
  const featuredImageProps = {
    blurImage: placeholder.base64,
    ...(placeholder.img as Omit<ImageProps, 'placeholder'>),
  } as ImageProps

  return {
    ...product,
    featuredImageProps,
  }
}

const testProductSummaryBySlug = gql`
  query testProductSummaryBySlug($slug: ID!) {
    productBySlug(slug: $slug) {
      __typename
      ... on LabTestProduct {
        sku
        name
        description
        slug
        type
        featuredImage {
          src
          name
        }
      }
    }
  }
`

export async function getTrendingArticles(articleSlugs: string[]) {
  const { data } = await client.query<MainPageArticlesQuery, MainPageArticlesQueryVariables>({
    query: mainPageArticles,
    variables: { articleSlugs },
  })

  const relatedArticles = resolveArticlesBySlugs({
    slugs: articleSlugs,
    articles: data.articlesBySlug,
  })

  return relatedArticles
}

const mainPageArticles = gql`
  query MainPageArticles($articleSlugs: [String!]!) {
    articlesBySlug(slugs: $articleSlugs) {
      __typename
      ...BlogCard
    }
  }

  ${BlogCardFragmentDoc}
`

interface Benefit {
  image: string
  content: React.ReactNode
}

const benefits: Benefit[] = [
  {
    image: '/images/icons/iconHeartFlower.svg',
    content: (
      <span>
        A thorough <span className="font-normal">health check</span> to understand what&apos;s right, what&apos;s wrong
        and what you might need to do.
      </span>
    ),
  },
  {
    image: '/images/icons/iconGirlWithHeart.svg',
    content: (
      <span>
        An annual <span className="font-normal">in-depth consultation</span> with your personal doctor.
      </span>
    ),
  },
  {
    image: '/images/icons/iconsTableChart.svg',
    content: (
      <span>
        A bespoke <span className="font-normal">health plan</span> so you&apos;re clear on your goals and how to achieve
        them.
      </span>
    ),
  },
  {
    image: '/images/icons/iconTwoPeople.svg',
    content: (
      <span>
        Regular <span className="font-normal">one-to-ones</span> with your doctor to keep you on track.
      </span>
    ),
  },
  {
    image: '/images/icons/iconTest.svg',
    content: (
      <>
        <span className="font-normal">Regular health checks</span>
        <span> to track your progress.</span>
      </>
    ),
  },
]

const Benefit = ({ benefit }: { benefit: Benefit }) => (
  <div className="flex items-center gap-5 rounded-xl bg-selphWhite-100 p-4">
    <div className="relative flex h-12 min-w-12 justify-center overflow-hidden rounded-lg align-middle">
      <UI.Image src={benefit.image} alt="Feature icon" fill className="bg-selphGreen-200 p-2 text-selphGreen-500" />
    </div>

    <UI.Paragraph color="black" className="text-start" size="large">
      {benefit.content}
    </UI.Paragraph>
  </div>
)
