import { BlogCardFragment } from '../gql'

/**
 * Resolves an array of slugs to their corresponding articles, ensuring that each slug has a matching article.
 *
 * @template T - The type of articles (e.g., BlogCardFragment, BlogLinkFragment).
 * @param {Object} params - The parameters for mapping articles.
 * @param {string[]} slugs - An array of slugs to resolve.
 * @param {(T | null)[]} articles - An array of articles, which may include null values.
 * @returns {T[]} An array of articles matching the provided slugs, in the same order.
 * @throws {Error} Throws an error if an article cannot be resolved for a given slug.
 */
export const resolveArticlesBySlugs = <T extends { slug: string }>({
  slugs,
  articles,
}: {
  slugs: string[]
  articles: (T | null)[]
}): T[] => {
  return slugs.map((slug) => {
    const item = articles?.find((article) => article?.slug === slug)

    if (!item) {
      throw new Error(`Article not found with slug: "${slug}"`)
    }

    return item
  })
}

/**
 * Maps an array of buttons to include additional data from a corresponding array of articles, ensuring each button has a matching article.
 *
 * @param {Object} params - The parameters for mapping buttons.
 * @param {{ slug: string; label: string }[]} buttons - An array of button objects containing slugs.
 * @param {(Pick<BlogCardFragment, 'path'| 'slug'> | null)[]} articles - An array of articles containing paths and slugs, which may include null values.
 * @returns {Array} An array of button objects, each including the `path` from the corresponding article.
 * @throws {Error} Throws an error if an article cannot be resolved for a given button.
 */
export const resolveButtonsWithPaths = ({
  buttons,
  articles,
}: {
  buttons: { slug: string; label: string }[]
  articles: (Pick<BlogCardFragment, 'path' | 'slug'> | null)[]
}): Array<{ slug: string; label: string; path: string }> => {
  return buttons.map((button) => {
    const item = articles.find((article) => article?.path && article.slug === button.slug)

    if (!item) {
      throw new Error(`Article not found with slug: "${button.slug}"`)
    }

    return {
      ...button,
      path: item.path,
    }
  })
}
